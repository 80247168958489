import clsx from 'clsx';
import { usePlayback } from 'src/state/playback';
import { useVideoContext } from 'src/state/video';
import { useContentInteractionTracking } from 'src/tracking/track-playback';
import { hlsPlaybackSupport } from 'src/utilities/streaming-helpers';

/**
 * A video tag that is used for playback, it is displayed below the player controls
 */
const SitePlayerVideoElement = () => {
  const { playerMode, currentStream } = usePlayback();

  const { videoRef, togglePlay } = useVideoContext();

  // track playback events in analytics
  useContentInteractionTracking(videoRef);

  // check how browser will play the HLS stream
  const hlsPlaybackMethod = hlsPlaybackSupport();

  // HLS.js doesn't reload the video tag when the stream changes,
  // so the video tag inherits subtitle/captions from the previous streams.
  // When using Native HLS playback we need the opposite, we want to keep the initialized video tag
  // as e.g. in Safari the background playback/autoplay is authorized only for the same video tag.
  const videoTagKey = (hlsPlaybackMethod === 'hlsJs' && currentStream?.url) || 'video-tag';

  return (
    <div
      className={clsx(
        'pointer-events-auto absolute bg-deepBlueC4',
        playerMode === 'mini' && 'bottom-0 left-0 h-16 lg:h-20',
        playerMode === 'maxi' && 'size-full',
        // hide the video tag when the player is not yet initialized,
        // we need to keep the video tag in the DOM for the autoplay to work
        playerMode === undefined && 'hidden',
      )}
    >
      <video
        ref={videoRef}
        className={clsx(
          'relative my-auto block w-full bg-deepBlueC4 object-contain md:h-full',
          playerMode === 'mini' && 'md:object-cover',
          playerMode === 'maxi' && 'mt-[148px] md:mt-0 sm:landscape:mt-0',
        )}
        // crossOrigin="use-credentials"
        controls={false}
        playsInline
        autoPlay
        preload="auto"
        key={videoTagKey}
        data-test="site-player-video-tag"
        // write the stream URL for debugging and testing purposes
        data-test-stream-url={currentStream?.url || ''}
        onClick={togglePlay}
      ></video>
    </div>
  );
};

export default SitePlayerVideoElement;
