import { LiveConcert } from 'generated/graphql';
import { Badge } from 'src/components/common/badge';
import RenderClientOnly from 'src/components/render-client-only';
import useLiveConcertState from 'src/hooks/use-live-concert-state';

type LiveConcertBadgeProps = {
  liveConcert: Pick<LiveConcert, 'typeDisplayName' | 'startTime' | 'endTime' | 'streamStartTime' | 'reruns'>;
};

/**
 * A LiveConcertBadge is used to display the type of live video (e.g. "LIVE" or "PREMIERE")
 * If a concert is in the past, it will not render a badge.
 */
export function LiveConcertBadge({ liveConcert }: LiveConcertBadgeProps) {
  const { status } = useLiveConcertState(liveConcert);

  // Don't render a badge if the live concert is in the past and has no scheduled reruns
  if (status === 'past') return null;

  return (
    <RenderClientOnly>
      <Badge color="liveNowRedC9">{liveConcert.typeDisplayName}</Badge>
    </RenderClientOnly>
  );
}
