import { PropsWithChildren } from 'react';
import clsx from 'clsx';

type BadgeProps = PropsWithChildren<{
  color?: 'white' | 'buttonBlueC8' | 'liveNowRedC9';
  small?: boolean;
}>;

/**
 * A Badge is used to display a small amount of information about a video element.
 * It comes in two sizes and three colors.
 *
 * @example
 * ```tsx
 * <Badge color="liveNowRedC9">Live</Badge> // Live badge
 * <Badge color="mainBgBlueC2" mini>DOCUMENTARY</Badge> // a small video Documentary badge
 * ```
 */
export function Badge({ children, color = 'white', small = false }: BadgeProps) {
  return (
    <span
      className={clsx(
        'dg-text-medium-5 inline-flex select-none rounded-[24px] uppercase',
        small
          ? 'px-2 py-0.5 leading-4 tracking-[0.25px]'
          : 'px-2 py-0.5 leading-[16px] shadow-[0_2px_10px_rgba(0,0,0,0.4)] md:px-2.5 md:leading-[20px] lg:px-3 lg:leading-[22px]',
        color === 'white' && 'bg-white/70 text-mainBgBlueC2 backdrop-blur-sm',
        color === 'liveNowRedC9' && 'bg-liveNowRedC9 text-white',
        color === 'buttonBlueC8' && 'bg-buttonBlueC8 text-white',
      )}
      data-test="badge"
    >
      {children}
    </span>
  );
}
