import { LiveConcert, LiveConcertRerun } from 'generated/graphql';
import { formatIcsDate } from 'src/utilities/ics';
import { getAbsoluteUrl, getNodePath } from 'src/utilities/url-helpers';

type UpcomingLiveConcertRerun = Pick<LiveConcertRerun, 'startTime' | 'endTime'>;
type UpcomingLiveConcertDatesInput = Pick<LiveConcert, 'startTime' | 'endTime'> & {
  reruns: UpcomingLiveConcertRerun[];
};
type UpcomingLiveConcertDatesPayload = { startTime: string; endTime: string }[];

/**
 * Takes a live concert node and returns an array of concert dates in the future.
 */
export function getUpcomingLiveConcertDates(
  liveConcert: UpcomingLiveConcertDatesInput,
): UpcomingLiveConcertDatesPayload {
  const { startTime, endTime, reruns } = liveConcert;
  return (
    [
      { startTime, endTime },
      ...reruns.map((rerun) => ({
        startTime: rerun.startTime,
        endTime: rerun.endTime,
      })),
    ]
      // check if stream or rerun is in the future
      .filter(({ startTime }) => new Date(startTime) > new Date())
  );
}

/**
 * Generate and download an ICS file for a Live Concert
 * @see https://www.rfc-editor.org/rfc/rfc5545.html
 */
export function generateLiveConcertIcal(
  liveConcert: Pick<LiveConcert, '__typename' | 'id' | 'title' | 'shortDescription' | 'startTime' | 'endTime'>,
): void {
  const { id, title, shortDescription, startTime, endTime } = liveConcert;
  const url = getAbsoluteUrl(getNodePath(liveConcert));
  // description is the short description (if available) plus the url
  const description = [shortDescription, url].filter(Boolean).join('\n\n');

  const icsLines = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'PRODID:STAGE+',
    'CALSCALE:GREGORIAN',
    'METHOD:PUBLISH',
    'BEGIN:VEVENT',
    `UID:${id}-${new Date(startTime).getTime()}`,
    `DTSTAMP:${formatIcsDate(new Date(Date.now()))}`,
    `DTSTART:${formatIcsDate(new Date(startTime))}`,
    `DTEND:${formatIcsDate(new Date(endTime))}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${description}`,
    `LOCATION:${url}`,
    `URL:${url}`,
    'BEGIN:VALARM',
    'ACTION:DISPLAY',
    'DESCRIPTION:REMINDER',
    'TRIGGER:-PT15M',
    'END:VALARM',
    'END:VEVENT',
    'END:VCALENDAR',
  ];
  const dataUrl = 'data:text/calendar;charset=utf-8,' + encodeURIComponent(icsLines.join('\r\n'));

  try {
    const save = document.createElementNS('http://www.w3.org/1999/xhtml', 'a') as HTMLAnchorElement;
    save.rel = 'noopener';
    save.href = dataUrl;
    save.target = '_blank';
    save.download = `${id}.ics`;
    const event = new MouseEvent('click', {
      view: window,
      button: 0,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(event);
    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  } catch (error) {
    console.error(error);
  }
}
