import { Fragment, useRef, PropsWithChildren } from 'react';
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react';
import CloseIcon from '@stageplus/icons/react/close';
import clsx from 'clsx';
import { ButtonIconWithBorder } from 'src/components/buttons/icon-button';
import useTranslate from 'src/hooks/use-translate';

export type ModalProps = {
  children?: React.ReactNode;
  open?: boolean;
  wide?: boolean;
  onClose: (force?: boolean) => void;
  dataTest?: string;
};

/**
 * Manually toggle the window scroll functionality,
 * important especially when the modal is open from another modal
 * In such cases HeadlessUI messes up the html element state and the scroll is not working properly
 * See following:
 * https://github.com/tailwindlabs/headlessui/issues/1000
 * https://github.com/tailwindlabs/headlessui/issues/1199
 * The clean up is called after the modal is open and when closed.
 * @todo [headlessui/react@>=2.0.0]: review if the issue is fixed in HeadlessUI, debug in WebDev tools using the 6x CPU slowdown
 */
const blockWindowScroll = (active: boolean) => () => {
  // reset the overflow of the html element if it was manually set
  setTimeout(() => {
    document.documentElement.style.overflow = active ? 'hidden' : 'initial';
    //  it has to be done asynchronously, after the modal animation is done
  }, 0);
};

/**
 * Modal overlay
 *
 * @component
 * @param children content of the modal
 * @param open display modal
 * @param onClose handle closing the modal
 */
export default function Modal({ children, open = false, onClose, dataTest, wide }: ModalProps) {
  const t = useTranslate();
  // lets have no visible focus element when the modal opens
  // the tabbing should work correctly though
  const noFocusRef = useRef(null);

  return (
    <Transition show={open} as={Fragment} afterLeave={blockWindowScroll(false)} afterEnter={blockWindowScroll(true)}>
      <Dialog onClose={onClose} className="relative z-40" initialFocus={noFocusRef} static>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-overlay" aria-hidden="true" data-test="dialog-overlay" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto" data-test={dataTest}>
          <div className="flex min-h-full items-end justify-center md:items-center md:py-8">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="transform-gpu opacity-0 translate-y-20"
              enterTo="transform-gpu opacity-100 translate-y-0"
              leave="ease-in duration-100"
              leaveFrom="transform-gpu opacity-100 translate-y-0"
              leaveTo="transform-gpu opacity-0 translate-y-20"
            >
              <DialogPanel
                className={clsx(
                  'relative min-h-[calc(var(--vh)*100)] bg-mainBgBlueC2 md:min-h-0 md:rounded-xl',
                  wide ? 'w-full md:w-[704px] 2xl:w-[758px]' : 'w-full md:w-[535px]',
                )}
              >
                <div className="absolute left-auto right-0 top-0 z-40 flex justify-end p-3 md:absolute">
                  <ButtonIconWithBorder
                    title={t('modal_close')}
                    icon={<CloseIcon />}
                    data-test="modal-close"
                    className="outline-none"
                    onClick={() => onClose(true)}
                  />
                </div>
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

/**
 * Modal content wrapper to be used when you want the default padding around the modal content
 */
Modal.Content = function ModalContent({ children }: PropsWithChildren<unknown>) {
  return <div className="px-10 py-6 lg:px-16 lg:py-12">{children}</div>;
};
