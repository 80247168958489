import { TimeHTMLAttributes } from 'react';
import useIntl from 'src/hooks/use-intl';
import { DateFormatStyle } from 'src/utilities/intl-helpers';

type TimeProps = TimeHTMLAttributes<HTMLTimeElement> & {
  value: string | number | Date; // Date primitive
  style: DateFormatStyle;
};

export default function Time({ value, style, ...rest }: TimeProps) {
  const { dateFormat } = useIntl();
  const date = value instanceof Date ? value : new Date(value);

  return (
    <time dateTime={date.toISOString()} className="lining-nums" {...rest} suppressHydrationWarning>
      {dateFormat(date, style)}
    </time>
  );
}
